<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm @submit.prevent="onSubmit">
                <h1>{{ $t('register.register') }}</h1>
                <p class="text-muted">{{ $t('register.create_acc') }}</p>
                <CInput
                  v-model.trim="email"
                  type="email"
                  :placeholder="$t('register.email')"
                  autocomplete="email"
                  required
                >
                  <template #prepend-content><CIcon name="cil-user" /></template>
                </CInput>
                <CSelect custom :value.sync="role" :placeholder="$t('register.role')" :options="roles" required>
                  <template #prepend-content><CIcon name="cil-user" /></template>
                </CSelect>
                <CInput
                  v-model.trim="password"
                  :placeholder="$t('register.password')"
                  type="password"
                  autocomplete="new-password"
                  required
                >
                  <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                </CInput>
                <CInput
                  v-model.trim="passwordRepeat"
                  :placeholder="$t('register.password_repeat')"
                  type="password"
                  autocomplete="new-password"
                  class="mb-4"
                  required
                  :invalid-feedback="error"
                  :is-valid="isValid"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                </CInput>
                <small v-if="errors === 409" class="form-error">{{ $t('errors.register_error') }}</small>
                <CButton type="submit" color="success" block :disabled="isSubmitting">
                  {{ $t('register.create_btn') }}
                </CButton>
              </CForm>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow class="justify-content-center">
                <CCol md="6">
                  <h4 class="text-center">
                    {{ $t('register.have_acc') }}
                  </h4>
                  <CButton color="success" block :to="{ name: 'login' }">
                    {{ $t('register.sign_in_btn') }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { REGISTER } from '@/store/action-types/auth';
import { CLEAR_ERRORS } from '@/store/mutation-types/auth';
import { mapState, mapMutations, mapActions } from 'vuex';
import { roles } from '@/helpers/const';

export default {
  name: 'Register',
  data() {
    return {
      email: '',
      role: '',
      password: '',
      passwordRepeat: '',
      error: '',
      isValid: null,
    };
  },
  computed: {
    ...mapState('auth', ['isSubmitting', 'errors']),
    roles() {
      return roles;
    },
  },
  beforeDestroy() {
    if (this.errors) {
      this.CLEAR_ERRORS();
    }
  },
  methods: {
    ...mapActions('auth', [REGISTER]),
    ...mapMutations('auth', [CLEAR_ERRORS]),
    onSubmit() {
      this.error = '';
      this.isValid = null;
      const isPasswordsMatch = this.password === this.passwordRepeat;

      if (!isPasswordsMatch) {
        this.error = this.$t('errors.passwords_not_match');
        this.isValid = false;
        return;
      }

      const credentials = {
        username: this.email,
        role: this.role,
        password: this.password,
      };

      this.REGISTER(credentials).then(() => {
        this.$router.push({ name: 'auth' });
      });
    },
  },
};
</script>
